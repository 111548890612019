<script setup lang="ts">
import Dropdown from '@components/dropdown.vue'
import { type MenuItem, MenuItemAction } from '@layouts/navigation.ts'
import { computed, ref } from 'vue'

import { useEmailAccounts } from '@composables/email-integration/use-email-accounts'

import { Button } from '../../components/button'

const props = defineProps<{
  item: MenuItem
  isSelected?: boolean
  hasRightChevron?: boolean
}>()

const isHovered = ref(false)
const isDropdownOpen = ref(false)

const { emailAccounts } = useEmailAccounts()

const showDotsOrDropdown = computed(() => {
  return (
    props.item.id === 'workbench-inbox' &&
    (isHovered.value || isDropdownOpen.value) &&
    (emailAccounts?.value?.length ?? 0) > 0
  )
})
</script>

<template>
  <Button
    v-if="item.type !== 'spacer'"
    size="md"
    variant="menu"
    :class="['my-1 w-full justify-start gap-3 text-left', isSelected ? 'bg-gray-250' : '']"
    :data-e2e="`app-bar-navigation-bar-item-${item.id}`"
    @mouseenter="isHovered = true"
    @mouseleave="isHovered = false"
  >
    <component
      :is="item.icon"
      v-if="item.icon"
      :class="['!h-6 min-h-6 !w-6 min-w-6', isSelected ? 'text-gray-1000' : 'text-gray-475']"
      :data-e2e="`app-bar-navigation-bar-item-${item.id}-icon${isSelected ? '-active' : ''}`"
    />
    <span class="truncate">{{ $t(item.name) }}</span>
    <span
      v-if="item.badge && !showDotsOrDropdown"
      class="text-gray-675 ml-auto text-sm proportional-nums"
      :data-e2e="`app-bar-navigation-bar-item-${item.id}-${item.badge}`"
    >
      {{ item.badge }}
    </span>
    <Dropdown
      v-if="showDotsOrDropdown"
      v-model:open="isDropdownOpen"
      :items="item.itemActions as MenuItemAction[]"
      class="ml-auto mr-1"
      content-classes="!p-1"
      item-classes="h-10 border-transparent rounded-sm"
      @click.stop
    >
      <INolasDotsHorizontal class="!h-6 !w-6 text-gray-600" :data-e2e="`app-bar-navigation-bar-item-${item.id}-menu`" />
    </Dropdown>
    <INolasChevronRight v-if="hasRightChevron" class="ml-auto !h-6 !w-6" />
  </Button>
  <div v-else class="h-4 bg-gray-100" />
</template>
