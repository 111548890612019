<script setup lang="ts">
import { lowerCase } from 'lodash'

import SelectItemContent from './_select-item-content.vue'
import SelectItem from './select-item.vue'

import { CustomSelectItemProps } from '.'

const props = defineProps<CustomSelectItemProps>()
</script>

<template>
  <SelectItem
    :value="item.value"
    :data-e2e="item?.e2e ? item?.e2e : `select-${name}-${lowerCase(item.value)}`"
    class="cursor-pointer truncate"
  >
    <SelectItemContent v-bind="props" />
  </SelectItem>
</template>
