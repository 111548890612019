<script setup lang="ts">
import SelectItemIcon from './_select-item-icon.vue'

import { CustomSelectItemProps } from '.'

const props = defineProps<CustomSelectItemProps>()
</script>

<template>
  <SelectItemIcon v-bind="props" />
  <p class="truncate">{{ translatable ? $t(item.label) : item.label }}</p>
</template>
