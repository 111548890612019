import { z } from '@hono/zod-openapi'
import { InferSelectModel, relations } from 'drizzle-orm'
import { AnyPgColumn, boolean, jsonb, pgEnum, pgTable, primaryKey, text } from 'drizzle-orm/pg-core'
import { createSelectSchema } from 'drizzle-zod'

import { id, pkPart, refId, timestamps } from '@epostbox/shared/database'

import { $addressAssignmentId, $addressId, AddressAssignmentID, AddressID } from './_ids'
import { addressAssignmentSharing } from './address-assignment-sharing'
import { ContactProperties } from './address-book-properties'
import { UserID, WorkspaceID } from './auth/_ids'
import { users } from './auth/user'
import { handshake, mailboxPreference } from './handshake'

export const EntryType = z.enum(['organisation', 'person', 'authority'])
export const entryType = pgEnum('address_entry_type', [
  EntryType.Enum.organisation,
  EntryType.Enum.person,
  EntryType.Enum.authority,
])

export const ContactData = z.object({
  name: z.string(),
  type: EntryType.optional(),
  isHeadquarter: z.boolean().optional(),
  properties: z.array(ContactProperties),
})

export type ContactData = z.infer<typeof ContactData>

export const AssignmentContactData = z.record(z.string(), ContactData.optional())
export type AssignmentContactData = z.infer<typeof AssignmentContactData>

export const addressEntry = pgTable('address_entry', {
  id: id<AddressID>($addressId.prefix),

  handshakenUserId: refId<UserID>('handshaken_user_id'),
  handshakenWorkspaceId: refId<WorkspaceID>('handshaken_workspace_id'),

  type: entryType('type').notNull(),

  contactData: jsonb('contact_data').$type<AssignmentContactData>(),

  isMain: boolean('is_main').default(false),
  defaultPreference: mailboxPreference('default_preference'),
})
export const addressEntryRealations = relations(addressEntry, ({ one }) => ({
  user: one(users, {
    fields: [addressEntry.handshakenUserId],
    references: [users.id],
  }),
}))
export const AddressEntryRecord = createSelectSchema(addressEntry, {
  id: AddressID,
  handshakenUserId: UserID,
  handshakenWorkspaceId: WorkspaceID,
  contactData: AssignmentContactData,
})
export type AddressEntryRecord = InferSelectModel<typeof addressEntry>

export const AssignmentType = z.enum([
  'workspace_folder',
  'external_folder',
  'private_folder',
  'blocked_folder',
  'admin_folder',
  'entry',
])
export const assignmentType = pgEnum('address_assignment_type', [
  AssignmentType.Enum.workspace_folder,
  AssignmentType.Enum.external_folder,
  AssignmentType.Enum.private_folder,
  AssignmentType.Enum.blocked_folder,
  AssignmentType.Enum.admin_folder,
  AssignmentType.Enum.entry,
])
export type AssignmentType = z.infer<typeof AssignmentType>
export type EntryType = z.infer<typeof EntryType>

export const addressAssignment = pgTable(
  'address_assignment',
  {
    id: pkPart<AddressAssignmentID>($addressAssignmentId.prefix),
    workspaceId: refId<WorkspaceID>('workspace_id').notNull(),

    createdBy: refId<UserID>('created_by').notNull(),
    updatedBy: refId<UserID>('updated_by'),

    parentId: refId<AddressAssignmentID>('parent_id').references((): AnyPgColumn => addressAssignment.id, {
      onDelete: 'restrict',
    }),

    /**
     * Contacts can be related to one another - such as members of an organisation are related to that organisation contact.
     */
    relatedTo: jsonb('related_to').$type<AddressAssignmentID>(),

    /**
     * Contacts are stored in a hierarchy. To define the top of the hierarchy we have 3 main folders.
     * - `workspace_folder` is the organisation folder. Here all members of the company are grouped.
     * - `external_folder` contains external contacts, handshaken or otherwise.
     * - `private_folder` exists for every user and only the respective user can see the contacts inside of it.
     *
     * In addition, `folders` can be created as children of the main folders. There is no limit on the depth currently.
     *
     * Every folder contains an arbitrary amount of `entry` records.
     *
     * Every `entry` assignment is also linked to an `address_entry` where the current contact data is stored.
     * The `workspace_folder` is an exception in this structure because it will also be linked to an `address_entry`
     * because every company can have its own address.
     */
    type: assignmentType('type').notNull(),
    entryId: refId<AddressID>('entry_id').references(() => addressEntry.id, { onDelete: 'set null' }),

    /** Set only when `type = 'folder'`. */
    name: text('name'),

    /**
     * Stores the initial contact data from the moment the address was created as fallback.
     *
     * To be used when a handshaken contact will __end the handshake__, resulting in the other
     * party losing access to the main entry, therefore we need to fall back to the initial data.
     */
    contactData: jsonb('contact_data').$type<EntryContactData>(),

    ...timestamps(),
  },
  table => ({
    pk: primaryKey({ columns: [table.id, table.workspaceId] }),
  })
)

export const addressAssignmentRelations = relations(addressAssignment, ({ one, many }) => ({
  entry: one(addressEntry, {
    fields: [addressAssignment.entryId],
    references: [addressEntry.id],
  }),
  parent: one(addressAssignment, {
    fields: [addressAssignment.parentId],
    references: [addressAssignment.id],
  }),
  folderSharing: many(addressAssignmentSharing),
  handshake: one(handshake, {
    fields: [addressAssignment.id],
    references: [handshake.addressAssignmentId],
  }),
}))
export const AddressAssignmentRecord = createSelectSchema(addressAssignment, {
  id: AddressAssignmentID,
  contactData: AssignmentContactData,
})
export type AddressAssignmentRecord = InferSelectModel<typeof addressAssignment>
export type FolderType = Exclude<AddressAssignmentRecord['type'], 'entry'>

export const EntryContactData = z.object({
  latest: ContactData.optional(),
})
export type EntryContactData = z.infer<typeof EntryContactData>

// Public domain objects
export const Address = z
  .object({
    assignmentId: AddressAssignmentID,
    type: EntryType,
    contactData: ContactData.optional(),

    createdAt: z.date(),
    updatedAt: z.date().optional(),
  })
  .openapi('Address')
export type Address = z.infer<typeof Address>

export const AssignmentData = z.object({
  id: AddressAssignmentID,
  parentId: AddressAssignmentID.nullable(),
  relatedTo: AddressAssignmentID.nullable(),

  type: AssignmentType,
  name: z.string().nullable(),
  workspaceId: WorkspaceID,
  entryId: AddressID.nullable(),
  createdBy: UserID,
  updatedBy: UserID.nullable(),

  contactData: z.record(z.string(), ContactData.partial().optional()).nullable(),

  createdAt: z.date(),
  updatedAt: z.date().nullable(),
  deletedAt: z.date().nullable(),
})

export const Folder = AssignmentData.extend({
  type: AssignmentType.exclude(['entry']),
}).openapi('Folder')

export type Folder = z.infer<typeof Folder>

export const ImportContactsBase = z.object({
  Name: z.string(),
  ContactType: z.string(),
  Email: z.string(),
  Country: z.string(),
  Phone: z.string().optional(),
  Fax: z.string().optional(),
  Function: z.string().optional(),
  Departament: z.string().optional(),
  SocialMedia: z.string().optional(),
  Website: z.string().optional(),
  Notes: z.string().optional(),
})

export const ImportContactsDe = z.object({
  Region: z.string(),
  Street: z.string(),
  City: z.string(),
  No: z.number(),
  ZipCode: z.number(),
})

export const ImportContactsOtherCountries = z.object({
  AddressLine: z.string(),
})

export const ImportContactsAddress = z.union([ImportContactsDe, ImportContactsOtherCountries])
export const ImportContects = ImportContactsBase.and(ImportContactsAddress)
export enum ImportContactType {
  Individual = 'person',
  Organization = 'organisation',
  Authority = 'authority',
}
export type ImportContacts = z.infer<typeof ImportContects>

export const AddressCreate = Address.pick({ type: true }).extend({
  contactData: ContactData,
  folder: AddressAssignmentID.nullable().optional(),
  relatedTo: AddressAssignmentID.optional(),
})
export const CreateBulkAddress = Address.pick({ type: true }).extend({
  contactData: ContactData,
  relatedTo: AddressAssignmentID.optional(),
})

export type CreateBulkAddress = z.infer<typeof CreateBulkAddress>
export type AddressCreate = z.infer<typeof AddressCreate>

export function mapToAddress(assignment: AddressAssignmentRecord, type: EntryType): Address {
  return {
    assignmentId: assignment.id,
    type: type,
    contactData: assignment.contactData?.latest,
    createdAt: assignment.createdAt,
    updatedAt: assignment.updatedAt || undefined,
  }
}
