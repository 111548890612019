<script setup lang="ts">
import { watchEffect } from 'vue'
import { useLocalePath } from 'vue-i18n-routing'

import { Button } from '@epostbox/ui/button'
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from '@epostbox/ui/dropdown-menu'
import { cn } from '@epostbox/ui/utils'

const localePath = useLocalePath()

export interface Item {
  id: string
  label: string
  icon?: unknown
  iconRight?: unknown
  url?: string
  type?: string
  disabled?: boolean
  callback?: () => void
}

withDefaults(
  defineProps<{
    items: Item[]
    isStatic?: boolean
    contentClasses?: string
    itemClasses?: string
    iconRightClasses?: string
    align?: 'center' | 'start' | 'end'
    dataE2e?: string
  }>(),
  {
    isStatic: false,
    contentClasses: '',
    itemClasses: '',
    iconRightClasses: '',
    align: 'end',
    dataE2e: '',
  }
)
defineEmits<{
  'click-item': [value: string]
}>()

const open = defineModel<boolean>('open', { default: false })

watchEffect(() => {
  const element = document.querySelector('.dropdown-menu-wrapper')
  if (open.value) {
    element?.classList.add('!overflow-hidden')
  } else {
    element?.classList.remove('!overflow-hidden')
  }
})
</script>

<template>
  <div>
    <DropdownMenu :open="open" @update:open="open = $event">
      <DropdownMenuTrigger as-child :disabled="true">
        <div @mousedown.prevent="open = !open">
          <slot />
        </div>
      </DropdownMenuTrigger>
      <DropdownMenuContent :class="cn('w-fit', contentClasses)" :align="align" hide-when-detached avoid-collisions>
        <template v-for="(item, index) in items" :key="item.id">
          <div v-if="item.id === 'divider'" class="px-4">
            <hr class="my-2 h-px w-full bg-gray-250" />
          </div>

          <DropdownMenuItem v-else as-child class="min-w-fit cursor-pointer" :data-e2e="`${dataE2e}-${item.id}`">
            <Button
              v-if="isStatic"
              :as="item.url ? 'router-link' : 'button'"
              variant="transparent"
              :disabled="item.disabled"
              :class="
                cn('h-[46px] w-full justify-start rounded-none bg-white text-left hover:bg-gray-200', itemClasses)
              "
              @click="$emit('click-item', item.id)"
            >
              <component :is="item.icon" v-if="item.icon" class="mr-2 h-4 w-4" />
              <span>{{ $t(item.label) }}</span>
            </Button>
            <Button
              v-else
              :as="item.url ? 'router-link' : 'button'"
              :class="
                cn(
                  'h-[46px] !w-full justify-start !gap-2 whitespace-nowrap rounded-none bg-white text-left hover:bg-gray-200',
                  itemClasses
                )
              "
              :disabled="item.disabled"
              variant="transparent"
              v-bind="item.url ? { to: localePath(item.url) } : {}"
              v-on="item.callback ? { click: item.callback } : {}"
            >
              <component :is="item.icon" v-if="item.icon" class="pointer-events-none h-5 w-5 min-w-5 text-gray-350" />
              <div class="pointer-events-none flex items-center gap-x-2.5">
                <span v-if="item.type" class="mr-4 font-medium text-gray-600">{{ `${item.type}  ${index + 1}` }}</span>
                <span class="mr-4 whitespace-nowrap">{{ $t(item.label) }}</span>
              </div>
              <component
                :is="item.iconRight"
                v-if="item.iconRight"
                :class="cn('pointer-events-none ml-auto h-5 w-5 min-w-5 text-gray-350', iconRightClasses)"
              />
            </Button>
          </DropdownMenuItem>
        </template>
      </DropdownMenuContent>
    </DropdownMenu>
  </div>
</template>
