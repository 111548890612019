import { type InferInsertModel, type InferSelectModel, relations } from 'drizzle-orm'
import { jsonb, pgTable } from 'drizzle-orm/pg-core'
import { createInsertSchema, createSelectSchema } from 'drizzle-zod'
import { z } from 'zod'

import { id, refId } from '@epostbox/shared/database'

import { $addressAssignmentSharingId, AddressAssignmentID, AddressAssignmentSharingID } from './_ids'
import { addressAssignment } from './address-book'
import { WorkspaceID } from './auth/_ids'

export const AddressAssignmentSharingAction = z.enum([
  // Previously "read"
  'view',

  // Previously "write", separated as 3 distinct permissions
  'edit',
  'share',
  'invite',
])
export type AddressAssignmentSharingAction = z.infer<typeof AddressAssignmentSharingAction>

export const AddressAssignmentActions = z.array(AddressAssignmentSharingAction)
export type AddressAssignmentActions = z.infer<typeof AddressAssignmentActions>

export const addressAssignmentSharing = pgTable('address_assignment_sharing', {
  id: id<AddressAssignmentSharingID>($addressAssignmentSharingId.prefix),
  workspaceId: refId<WorkspaceID>('workspace_id').notNull(),
  assignmentId: refId<AddressAssignmentID>('assignment_id')
    .notNull()
    .references(() => addressAssignment.id, { onDelete: 'cascade' }),
  sharedAssignmentId: refId<AddressAssignmentID>('shared_assignment_id')
    .notNull()
    .references(() => addressAssignment.id, { onDelete: 'cascade' }),

  // /** @deprecated; @see actions */
  // action: text('action', { enum: ['read', 'write'] }).notNull(),

  actions: jsonb('actions').$type<AddressAssignmentActions>().default([]),
})

const AddressAssignmentSharing = {
  id: AddressAssignmentSharingID,
  workspaceId: WorkspaceID,
  assignmentId: AddressAssignmentID,
  sharedAssignmentId: AddressAssignmentID,
  actions: AddressAssignmentActions,
}

export const AddressAssignmentSharingRecord = createSelectSchema(addressAssignmentSharing, AddressAssignmentSharing)
export type AddressAssignmentSharingRecord = InferSelectModel<typeof addressAssignmentSharing>

export const AddressAssignmentSharingRecordCreate = createInsertSchema(
  addressAssignmentSharing,
  AddressAssignmentSharing
)
export type AddressAssignmentSharingRecordCreate = InferInsertModel<typeof addressAssignmentSharing>

export const addressAssignmentSharingRelations = relations(addressAssignmentSharing, ({ one }) => ({
  assignment: one(addressAssignment, {
    fields: [addressAssignmentSharing.assignmentId],
    references: [addressAssignment.id],
  }),
  sharedAssignment: one(addressAssignment, {
    fields: [addressAssignmentSharing.sharedAssignmentId],
    references: [addressAssignment.id],
  }),
}))
