import { useVirtualList } from '@vueuse/core'
import { computed } from 'vue'

import type {
  CustomSelectContentProps,
  CustomSelectGroupProps,
  CustomSelectItemProps,
  Item,
  ItemsByGroup,
  SelectProps,
} from '.'

export function bindCommonProps(
  props: SelectProps | CustomSelectContentProps | CustomSelectGroupProps
): Partial<CustomSelectContentProps | CustomSelectGroupProps | CustomSelectItemProps> {
  return {
    iconClasses: props.iconClasses,
    name: props.name,
    translatable: props.translatable,
  }
}

export function isListItems(items: SelectProps['items']): items is Item[] {
  if (!items || !items[0]) return false

  const [item] = items

  return !('title' in item)
}

export function isListGroups(items: SelectProps['items']): items is ItemsByGroup[] {
  if (!items || !items[0]) return false

  const [item] = items

  return 'title' in item
}

export function useVirtualItems(items: SelectProps['items']) {
  const virtualListConfig = { itemHeight: 48 } as const
  const virtualItems = useVirtualList(isListItems(items) ? items : [], virtualListConfig)
  const virtualGroups = useVirtualList(isListGroups(items) ? items : [], virtualListConfig)

  return computed(() => {
    if (isListGroups(items)) {
      return virtualGroups
    }
    return virtualItems
  })
}
