import { eq, InferInsertModel, InferSelectModel, relations } from 'drizzle-orm'
import { boolean, index, jsonb, pgEnum, pgTable, primaryKey, text, uniqueIndex, varchar } from 'drizzle-orm/pg-core'
import { createInsertSchema, createSelectSchema } from 'drizzle-zod'
import { z } from 'zod'

import { pkPart, refId, timestamps } from '@epostbox/shared/database'
import { FileMimeType } from '@epostbox/shared/filetypes'

import {
  $workflowId,
  AddressAssignmentID,
  CostCenterID,
  LetterheadID,
  SignatureID,
  WorkflowID,
  WorkflowInterfaceID,
} from './_ids'
import { UserID, WorkspaceID } from './auth/_ids'
import { users } from './auth/user'
import { workspaces } from './auth/workspace'
import { costCenter } from './cost-center'
import { DecomposedAddress } from './documents'
import { workflowOutput } from './workflow-output'
import { workflowRun } from './workflow-run'

export const WorkflowType = {
  Incoming: 'INCOMING',
  Outgoing: 'OUTGOING',
} as const
export type WorkflowType = (typeof WorkflowType)[keyof typeof WorkflowType]
export const typeEnum = pgEnum('type_enum', [WorkflowType.Incoming, WorkflowType.Outgoing])

const StepType = ['all_users', 'department', 'working_group', 'branch_office', 'automatic'] as const
type StepType = (typeof StepType)[number]

const NodeType = ['trigger', 'step', 'condition', 'condition-branch'] as const
export type NodeType = (typeof NodeType)[number]

const Channel = z.enum(['NOLAS', 'EMAIL', 'POST', 'SFTP'])

const IncomingPermissions = z.object({
  editAiData: z.boolean(),
})

const OutgoingPermissions = z.object({
  sendAutomatically: z.boolean(),
  requestDigitalShipping: z.boolean(),
  printBackground: z.boolean(),
  printDuplex: z.boolean(),
  printColor: z.boolean(),
  recyclingPaper: z.boolean(),
  parcelStamps: z.boolean(),
  internetStamps: z.boolean(),
  additionalServices: z.boolean(),
  textTemplates: z.boolean(),
  sendDeliveryOrder: z.boolean(),
})
export type IncomingPermissions = z.infer<typeof IncomingPermissions>
export type OutgoingPermissions = z.infer<typeof OutgoingPermissions>

export const IncomingPolicy = z.object({
  visibility: z.enum(['Single', 'Group']),
  permissions: IncomingPermissions.partial().optional(),
})
export type IncomingPolicy = z.infer<typeof IncomingPolicy>

//#region ids schemas
// If we put directly the ulid id types, the recursive Steps type will throw an error
// that's why we added these string with refinments
const _SignatureID = z.string().refine(value => SignatureID.parse(value))
const _LetterheadID = z.string().refine(value => LetterheadID.parse(value))
const _AddressAssignmentID = z.string().refine(value => AddressAssignmentID.parse(value))
const _UserID = z.string().refine(value => UserID.parse(value))
// #endregion

export const OutgoingChannel = z.enum([Channel.Enum.EMAIL, Channel.Enum.NOLAS, Channel.Enum.POST])
export type OutgoingChannel = z.infer<typeof OutgoingChannel>

export const OutgoingPolicy = z.object({
  visibility: z.enum(['Single', 'Group']),
  channels: z.array(OutgoingChannel).optional(),
  permissions: OutgoingPermissions.partial().optional(),
  signatures: z.array(_SignatureID).optional(),
  letterheads: z.array(_LetterheadID).optional(),
})
export type OutgoingPolicy = z.infer<typeof OutgoingPolicy>

export const AccessPolicy = z.union([IncomingPolicy, OutgoingPolicy])
export type AccessPolicy = z.infer<typeof AccessPolicy>

export const StepAddressAssignment = z.object({
  type: z.enum(['workspace_folder', 'entry']),
  id: _AddressAssignmentID,
  userId: _UserID.nullish(),
})
export type StepAddressAssignment = z.infer<typeof StepAddressAssignment>

export const StepUserPermission = z.object({
  addressAssignments: z.union([z.literal('all'), z.array(StepAddressAssignment)]),
  globalPolicy: AccessPolicy.optional(),
  individualPolicy: z.record(_AddressAssignmentID, AccessPolicy).optional(),
})
export type StepUserPermission = z.infer<typeof StepUserPermission>

const StepNodeData = StepUserPermission.extend({
  label: z.string(),
  description: z.string().nullish(),
  type: z.enum(StepType),
  approval: z
    .object({
      condition: z.string(),
      approved: z.boolean().nullish(),
      approvalReason: z.string().nullish(),
      approvalUsers: z.array(StepAddressAssignment).nullish(),
      checkedBy: z.string().nullish(),
      passNodeId: z.string(),
      failNodeId: z.string(),
    })
    .optional(),
})
export type StepNodeData = z.infer<typeof StepNodeData>

export const TriggerNodeData = z.object({ trigger: z.string() })
export type TriggerNodeData = z.infer<typeof TriggerNodeData>

export const LogicalOperator = z.enum(['and', 'or'])
export type LogicalOperator = z.infer<typeof LogicalOperator>

export const ConditionSchemas = {
  name: z.array(z.string().refine(val => val.trim().length > 0, 'String should not be empty.')),
  zip: z
    .tuple([z.number(), z.number()])
    .refine(([from, to]) => from < to, { message: 'Zip from should be less than zip to.' }),
  address: z.string().refine(val => val.trim().length > 0, 'String should not be empty.'),
  email: z.array(
    z
      .string()
      .email()
      .refine(val => val.trim().length > 0, 'String should not be empty.')
  ),
  content: z.object({
    classification: z.string(),
    subclassification: z.string(),
    entitites: z.array(z.string()),
  }),
  invoiceAmount: z.number(),
} as const
type ConditionValue<T extends keyof typeof ConditionSchemas> = z.infer<(typeof ConditionSchemas)[T]>

export const Condition = <T extends keyof typeof ConditionSchemas>(name: T) =>
  z.object({
    operator: LogicalOperator.optional(),
    name: z.literal(name),
    value: ConditionSchemas[name] as z.ZodType<ConditionValue<T>>,
  })
export type Condition<T extends keyof typeof ConditionSchemas> = z.infer<ReturnType<typeof Condition<T>>>

export const ConditionNodeData = z.object({
  checkValue: z.array(Condition('invoiceAmount').extend({ conditionOperator: z.enum(['eq', 'gt', 'lt']) })),
  matchCondition: z.boolean().optional(),
  passNodeId: z.string(),
  failNodeId: z.string(),
})
// .partial()
export type ConditionNodeData = z.infer<typeof ConditionNodeData>

export const ConditionBranchNodeData = z.object({
  conditionValue: z.enum(['yes', 'no']),
  parentConditionId: z.string(),
})
export type ConditionBranchNodeData = z.infer<typeof ConditionBranchNodeData>

export const NodeData = z.union([TriggerNodeData, StepNodeData, ConditionNodeData, ConditionBranchNodeData])
export type NodeData = z.infer<typeof NodeData>

export const Node = z.object({
  id: z.string(),
  type: z.enum(NodeType),
  position: z.object({ x: z.number(), y: z.number() }),
  selected: z.boolean().optional(),
  data: NodeData,
})
export type Node = z.infer<typeof Node>

const ConnectionType = ['forward', 'approval', 'condition', 'simple'] as const
type ConnectionType = (typeof ConnectionType)[number]

export const Edge = z.object({
  id: z.string(),
  source: z.string(),
  target: z.string(),
  type: z.literal('custom'),
  selected: z.boolean().optional(),
  data: z.object({
    connection: z.enum(ConnectionType),
    previousTargetNode: z.string().optional(),
    approvalMessage: z.string().optional(),
  }),
})
export type Edge = z.infer<typeof Edge>

export const WorkflowBuilderConfig = z.object({
  edges: z.record(z.string(), Edge),
  nodes: z.record(z.string(), Node),
})
export type WorkflowBuilderConfig = z.infer<typeof WorkflowBuilderConfig>

export type Step = {
  id: string
  type: 'step' | 'condition'
  data: StepNodeData | ConditionNodeData
  success?: Steps
  fail?: Steps
}
export type Steps = Step[]

export const Step: z.ZodType<Step> = z
  .lazy(() =>
    z.object({
      id: z.string(),
      type: z.enum(['condition', 'step']),
      data: StepNodeData.or(ConditionNodeData),
      success: Steps.optional(),
      fail: Steps.optional(),
    })
  )
  .openapi({ type: 'object' })
export const Steps: z.ZodType<Steps> = z
  .lazy(() =>
    z.array(
      z.object({
        id: z.string(),
        type: z.enum(['condition', 'step']),
        data: StepNodeData.or(ConditionNodeData),
        success: Steps.optional(),
        fail: Steps.optional(),
      })
    )
  )
  .openapi({ type: 'object' })

export const DecomposedAddressCondition = Condition('address').extend({
  decomposedAddress: DecomposedAddress.optional(),
})
export type DecomposedAddressCondition = z.infer<typeof DecomposedAddressCondition>

export const SenderConditions = z.array(z.union([Condition('name'), Condition('zip'), DecomposedAddressCondition]))
export type SenderConditions = z.infer<typeof SenderConditions>

export const RecipientConditions = z.array(
  z.union([Condition('email'), Condition('name'), Condition('zip'), DecomposedAddressCondition])
)
export type RecipientConditions = z.infer<typeof RecipientConditions>

export const DocumentContentConditions = z.array(Condition('content'))
export type DocumentContentConditions = z.infer<typeof DocumentContentConditions>

const LogicalConditions = <T>(schema: z.ZodSchema<T>) =>
  z
    .object({
      conditions: schema,
      conditionsOrder: z.array(schema),
    })
    .partial()

const WorkflowTriggerChannel = z.union([z.enum(['NOLAS', 'EMAIL']), WorkflowInterfaceID])
export type WorkflowTriggerChannel = z.infer<typeof WorkflowTriggerChannel>

export const Triggers = z
  .object({
    channel: z.array(WorkflowTriggerChannel),
    fileFormat: z.array(z.nativeEnum(FileMimeType)),
    content: LogicalConditions(DocumentContentConditions),
    recipient: LogicalConditions(RecipientConditions),
    sender: LogicalConditions(SenderConditions),
    signatureHash: z.string(),
  })
  .partial()

export type Triggers = z.infer<typeof Triggers>

export const StateMachine = z.object({ steps: Steps })
export type StateMachine = z.infer<typeof StateMachine>

export const WorkflowSettings = z.object({
  allowedConversions: z.array(
    z.object({
      from: z.nativeEnum(FileMimeType).or(z.literal('any')),
      to: z.nativeEnum(FileMimeType),
      effect: z.enum(['allow', 'deny']),
    })
  ),
})
export type WorkflowSettings = z.infer<typeof WorkflowSettings>

export const workflow = pgTable(
  'workflow',
  {
    id: pkPart<WorkflowID>($workflowId.prefix),
    workspaceId: refId<WorkspaceID>('workspace_id')
      .notNull()
      .references(() => workspaces.id, { onDelete: 'cascade' }),

    createdBy: refId<UserID>('created_by')
      .notNull()
      .references(() => users.id, { onDelete: 'set null' }),
    name: text('name').notNull(),
    description: text('description'),
    type: typeEnum('type').notNull(),
    isActive: boolean('is_active').default(false).notNull(),
    isFallback: boolean('is_fallback').default(false).notNull(),
    startAction: varchar('start_action', { enum: ['Manual', 'Automatic'] }).notNull(),
    stateMachine: jsonb('state_machine').$type<StateMachine>(),
    builderConfig: jsonb('builder_config').$type<WorkflowBuilderConfig>(),
    triggers: jsonb('triggers').$type<Triggers>(),
    costCenter: refId<CostCenterID>('cost_center_id').references(() => costCenter.id, { onDelete: 'set null' }),
    authorizedUsers: jsonb('authorized_users').$type<UserID[]>(),
    settings: jsonb('settings').$type<WorkflowSettings>(),
    ...timestamps(),
  },
  table => ({
    pk: primaryKey({ columns: [table.id, table.workspaceId] }),
    uniqueFallbackIndex: uniqueIndex('unique_fallback_idx')
      .on(table.workspaceId, table.isFallback, table.type)
      .where(eq(table.isFallback, true)),
    stateMachineIndex: index('state_machine_idx').using('gin', table.stateMachine),
    builderConfigIndex: index('builder_config_idx').using('gin', table.builderConfig),
    triggersIndex: index('triggers_idx').using('gin', table.triggers),
  })
)

const WorkflowSchema = {
  id: WorkflowID,
  workspaceId: WorkspaceID,
  createdBy: UserID,
  stateMachine: StateMachine,
  builderConfig: WorkflowBuilderConfig,
  triggers: Triggers,
  costCenter: CostCenterID,
  authorizedUsers: z.array(UserID).nullish(),
  settings: WorkflowSettings.nullable(),
}
export const WorkflowRecord = createSelectSchema(workflow, WorkflowSchema)
export type WorkflowRecord = InferSelectModel<typeof workflow>

export const WorkflowRecordCreate = createInsertSchema(workflow, WorkflowSchema)
export type WorkflowRecordCreate = InferInsertModel<typeof workflow>

export const workflowRelations = relations(workflow, ({ many }) => ({
  runs: many(workflowRun),
  output: many(workflowOutput),
}))
