<script setup lang="ts">
import type { SelectRootEmits } from 'radix-vue'
import { SelectRoot, useForwardPropsEmits } from 'radix-vue'
import { computed } from 'vue'

import { cn } from '../../utils'

import type { SelectProps } from './'
import { getItem, SelectTrigger } from './'
import SelectContentVirtualized from './_select-content-virtualized.vue'
import SelectContent from './_select-content.vue'
import SelectedItemContent from './_select-item-content.vue'
import { bindCommonProps } from './utils'

type Emits = SelectRootEmits & {
  create: []
}
const props = defineProps<SelectProps>()
const emits = defineEmits<Emits>()
const forwarded = useForwardPropsEmits(props, emits)
const open = defineModel<boolean>('open')

const contentProps = computed(() => ({
  ...bindCommonProps(props),
  items: props.items,
  class: cn('max-h-96', props.contentClasses),
}))

const selectedItem = computed(() => getItem(props.items, props.modelValue))
</script>

<template>
  <SelectRoot v-bind="forwarded" :class="[$attrs.class ?? '']" :disabled="disabled || readonly">
    <SelectTrigger
      :name="name!"
      :model-value="modelValue"
      :open="open!"
      :error="error"
      :trigger-classes="triggerClasses"
      :wrapper-classes="wrapperClasses"
      :color="color"
      :label="label"
      :disabled="disabled"
      :data-e2e="dataE2e"
      :required="required"
      :readonly="readonly"
      :clearable="clearable && !readonly"
      @update:model-value="emits('update:modelValue', $event || '')"
    >
      <div :class="cn('transition-all duration-75', props.modelValue ? 'visible' : 'invisible', props.iconClasses)">
        <slot name="icon" />
      </div>

      <span
        v-if="selectedItem"
        :class="['flex items-center gap-1.5 truncate', props.modelValue ? 'flex-1 text-gray-900' : 'text-gray-600']"
      >
        <SelectedItemContent
          v-bind="{ item: selectedItem, iconClasses: props.iconClasses, translatable: props.translatable }"
        />
      </span>
    </SelectTrigger>

    <SelectContentVirtualized v-if="props.useVirtualization" v-bind="contentProps" />
    <SelectContent v-else v-bind="contentProps" />
  </SelectRoot>
</template>
