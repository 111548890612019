import { event } from 'sst/event'
import { ZodValidator } from 'sst/event/validator'
import { z } from 'zod'

import { DocumentAssignmentID, DocumentID, InterfaceFTPUserID, UserID, WorkspaceID } from '@epostbox/db/domain'
import {
  DocumentAssignmentRecord,
  DocumentRecord,
  InterfaceRestApiUserID,
  PreprocessingStepName,
  WorkflowID,
  WorkflowInterfaceID,
  StepAddressAssignment,
  CostCenterID,
} from '@epostbox/db/schema'

export const defineEvent = event.builder({
  validator: ZodValidator,
  metadata() {
    return {}
  },
})

export namespace S3ObjectEvent {
  export const converted = defineEvent(
    's3object:converted',
    z.object({
      key: z.string(),
      bucket: z.string(),
      tasks: z.array(PreprocessingStepName.exclude(['CONVERT_TO_PDF'])).optional(),
    })
  )
}

export namespace DocumentEvent {
  const DocumentEventBase = z.object({
    userId: UserID,
    workspaceId: WorkspaceID,
    documentId: DocumentID,
    assignmentId: DocumentAssignmentID.optional(),
    workflowInterfaceId: WorkflowInterfaceID.optional(),
  })

  export const create = defineEvent(
    'documents:create',
    DocumentEventBase.extend({
      new: DocumentRecord.optional(),
    })
  )

  export const remove = defineEvent(
    'documents:remove',
    z.object({
      userId: UserID.optional(),
      documents: z.array(
        z.object({
          documentId: DocumentID,
          workspaceId: WorkspaceID,
        })
      ),
    })
  )

  export const update = defineEvent(
    'documents:update',
    DocumentEventBase.extend({
      old: DocumentRecord.partial().optional(),
      new: DocumentRecord.partial().optional(),
    })
  )

  export const preprocessingStarted = defineEvent('preprocessing:started', DocumentEventBase)
  export const preprocessingSuccess = defineEvent(
    'preprocessing:success',
    DocumentEventBase.extend({
      pagesCount: z.number(),
    })
  )
  export const preprocessingFailed = defineEvent(
    'preprocessing:failed',
    DocumentEventBase.extend({
      error: z.string().optional(),
    })
  )

  export const basicsTaskDone = defineEvent('basics-task:done', DocumentEventBase)
  export const eInvoiceTaskDone = defineEvent('einvoice-task:done', DocumentEventBase)
  export const summarizeTaskDone = defineEvent('summarize-task:done', DocumentEventBase)
  export const heuristicAddressDecompTaskDone = defineEvent('heuristic-address-decomp-task:done', DocumentEventBase)
  export const visualizeTaskDone = defineEvent('visualize-task:done', DocumentEventBase)
  export const readInvoiceTaskDone = defineEvent('read-invoice-task:done', DocumentEventBase)

  export const workflowDecideDone = defineEvent(
    'workflow-decide:done',
    DocumentEventBase.extend({ workflowId: WorkflowID, workflowName: z.string() })
  )
}

// Events related to SFTP Users
export namespace InterfaceSftpUserEvent {
  const SftpUserEventBase = z.object({
    ftpUserId: InterfaceFTPUserID,
    userId: UserID,
    workspaceId: WorkspaceID,
    interfaceId: WorkflowInterfaceID,
  })

  export const create = defineEvent('interface-sftp-user:create', SftpUserEventBase.extend({ createdAt: z.date() }))

  export const updatePassword = defineEvent(
    'interface-sftp-user:update-password',
    SftpUserEventBase.extend({ updatedAt: z.date() })
  )

  export const remove = defineEvent('interface-sftp-user:remove', SftpUserEventBase.extend({ deletedAt: z.date() }))
}

// Events related to SFTP Server's activity
export namespace SftpEvent {
  export const authenticate = defineEvent(
    'sftp:authenticate',
    z.object({
      ftpUserId: InterfaceFTPUserID,
      workspaceId: WorkspaceID,
      authenticatedAt: z.date(),
    })
  )
}

// Events related to Workflow Interfaces
export namespace WorkflowInterfaceEvent {
  const wInterfaceEventBase = z.object({
    workflowInterfaceId: WorkflowInterfaceID,
    workspaceId: WorkspaceID,
    userId: UserID,
  })

  export const create = defineEvent(
    'workflow-interface:create',
    wInterfaceEventBase.extend({
      createdAt: z.date(),
    })
  )

  export const remove = defineEvent(
    'workflow-interface:remove',
    wInterfaceEventBase.extend({
      deletedAt: z.date(),
    })
  )
}

// Events related to REST-API Users (credentials)
export namespace InterfaceRestApiUserEvent {
  const RestApiUserEventBase = z.object({
    restApiUserId: InterfaceRestApiUserID,
    userId: UserID,
    workspaceId: WorkspaceID,
    interfaceId: WorkflowInterfaceID,
  })

  export const create = defineEvent(
    'interface-rest-api-user:create',
    RestApiUserEventBase.extend({
      createdAt: z.date(),
    })
  )

  export const remove = defineEvent(
    'interface-rest-api-user:remove',
    RestApiUserEventBase.extend({
      deletedAt: z.date(),
    })
  )
}

export namespace EmailEvent {
  const EmailEventBase = z.object({
    recipients: z.array(z.string()).or(z.string()),
    attachements: z.array(z.string()),
    timestamp: z.date(),
    details: z.string(),
    subject: z.string(),
    body: z.string(),
    type: z.literal('external').or(z.literal('internal')),
    metadata: z.any(),
  })

  export const success = defineEvent('email:send:success', EmailEventBase.partial())
  export const failed = defineEvent('email:send:failed', EmailEventBase.partial())
}

export namespace WorkflowEvent {
  const WorkflowEventBase = z.object({
    document: DocumentRecord.optional(),
  })

  export const send = defineEvent(
    'workflow:send-documents',
    z.object({ assignments: z.array(DocumentAssignmentRecord) })
  )

  export const approve = defineEvent(
    'workflow:approve-document',
    WorkflowEventBase.extend({
      assignment: DocumentAssignmentRecord,
      event: z.enum(['APPROVAL', 'APPROVAL_REQUEST']),
      approvalReason: z.string().optional(),
      approvalUsers: z.array(StepAddressAssignment).optional(),
    })
  )

  export const workflowStepsCompleted = defineEvent(
    'workflow:steps-completed',
    WorkflowEventBase.extend({
      isFallback: z.boolean(),
      costCenter: CostCenterID.nullable(),
    })
  )
}
