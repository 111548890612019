import { jsonb, pgTable, primaryKey, text, timestamp, unique } from 'drizzle-orm/pg-core'
import { createInsertSchema, createSelectSchema } from 'drizzle-zod'
import { z } from 'zod'

import { pkPart, refId } from '@epostbox/shared/database'

import { $accountingReferenceId, AccountingReferenceID } from './_ids'
import { WorkspaceID } from './auth/_ids'

export const accountingReference = pgTable(
  'accounting_reference',
  {
    id: pkPart<AccountingReferenceID>($accountingReferenceId.prefix),
    workspaceId: refId<WorkspaceID>('workspace_id').notNull(),

    identifier: text('identifier').notNull(),
    description: text('description').notNull(),
    raw: jsonb('raw'),
    createdAt: timestamp('created_at').defaultNow().notNull(),
    updatedAt: timestamp('updated_at').defaultNow(),
  },
  table => ({
    pk: primaryKey({ columns: [table.id, table.workspaceId] }),
    uniqueIdentifier: unique('workspace_identifier_idx').on(table.workspaceId, table.identifier),
  })
)

const AccountingReferenceSchema = {
  id: AccountingReferenceID,
  workspaceId: WorkspaceID,
}

export const AccountingReferenceRecord = createSelectSchema(accountingReference, AccountingReferenceSchema)
export type AccountingReferenceRecord = z.infer<typeof AccountingReferenceRecord>

export const AccountingReferenceRecordCreate = createInsertSchema(accountingReference, AccountingReferenceSchema).omit({
  id: true,
  createdAt: true,
  updatedAt: true,
})
export type AccountingReferenceRecordCreate = z.infer<typeof AccountingReferenceRecordCreate>
